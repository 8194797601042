import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import ContactUs from './ContactUs';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/contact-us' element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;
