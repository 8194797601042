import logo from './AppIcon_NoBackground.png';
import headerLogo from './logo-inverted.png'
import downloadImage from './DownloadImage.png'
import './App.css';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function Home() {
    return (
        <div className="App">
            <Navbar className='App-header'>
                <img src={headerLogo} className='Header-logo' alt='Guide' />
            </Navbar>
            <header className="App-content">
                <img src={logo} className="App-logo" alt="logo" />
                <p className='Header-text'>
                The ultimate and groundbreaking way to connect with the spiritual realm! Get ready to experience the most modern method of spiritual divination. 
                </p>
                <a
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={downloadImage} className="App-link" alt='Download On Ios' />
                </a>
            </header>
            <footer className='App-footer'>
                <Link to="/contact-us" className='Footer-link'>Contact Us</Link>
                <Link to="/privacy-policy" className='Footer-link'>Privacy Policy</Link>
            </footer>
        </div>
    )
}

export default Home;